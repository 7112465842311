// Custom Theming for Angular Material
// https://material.angular.io/guide/theming

@use "sass:map";
@use "@angular/material" as mat;
@include mat.core();

// legalbot's blue color (#01082b) defined at hue 500
$legalbot-blue-palette: mat.define-palette(
  (
    50: #e1e1e6,
    100: #b3b5bf,
    200: #808495,
    300: #4d526b,
    400: #272d4b,
    500: #01082b,
    600: #010726,
    700: #010620,
    800: #01041a,
    900: #000210,
    A100: #5252ff,
    A200: #1f1fff,
    A400: #0000eb,
    A700: #0000d2,
    contrast: (
      50: #000000,
      100: #000000,
      200: #000000,
      300: #ffffff,
      400: #ffffff,
      500: #ffffff,
      600: #ffffff,
      700: #ffffff,
      800: #ffffff,
      900: #ffffff,
      A100: #ffffff,
      A200: #ffffff,
      A400: #ffffff,
      A700: #ffffff,
    ),
  ),
  500
);

// legalbot's pink color (#e62e6a) defined at hue 500
$legalbot-pink-palette: mat.define-palette(
  (
    50: #fce6ed,
    100: #f8c0d2,
    200: #f397b5,
    300: #ee6d97,
    400: #ea4d80,
    500: #e62e6a,
    600: #e32962,
    700: #df2357,
    800: #db1d4d,
    900: #d5123c,
    A100: #ffffff,
    A200: #ffd0d9,
    A400: #ff9daf,
    A700: #ff849a,
    contrast: (
      50: #000000,
      100: #000000,
      200: #000000,
      300: #000000,
      400: #000000,
      500: #ffffff,
      600: #ffffff,
      700: #ffffff,
      800: #ffffff,
      900: #ffffff,
      A100: #000000,
      A200: #000000,
      A400: #000000,
      A700: #000000,
    ),
  ),
  500
);

$default-typography: mat.define-typography-config();

$light-theme: mat.define-light-theme(
  (
    color: (
      primary: $legalbot-blue-palette,
      accent: $legalbot-pink-palette,
      warn: mat.define-palette(mat.$red-palette),
    ),
    typography: $default-typography,
    density: 0,
  )
);

@include mat.typography-hierarchy($default-typography);
@include mat.core-theme($light-theme);

// To avoid producing a large CSS file, we must import only the components we use.
// Uncomment the following lines to import only the desired components.
//@include mat.option-theme($light-theme);
//@include mat.optgroup-theme($light-theme);
//@include mat.pseudo-checkbox-theme($light-theme);
//@include mat.autocomplete-theme($light-theme);
//@include mat.badge-theme($light-theme);
//@include mat.bottom-sheet-theme($light-theme);
@include mat.button-theme($light-theme);
@include mat.fab-theme($light-theme);
@include mat.icon-button-theme($light-theme);
//@include mat.button-toggle-theme($light-theme);
@include mat.card-theme($light-theme);
//@include mat.checkbox-theme($light-theme);
@include mat.chips-theme($light-theme);
//@include mat.datepicker-theme($light-theme);
@include mat.dialog-theme($light-theme);
//@include mat.divider-theme($light-theme);
@include mat.expansion-theme($light-theme);
@include mat.form-field-theme($light-theme);
//@include mat.grid-list-theme($light-theme);
@include mat.icon-theme($light-theme);
@include mat.input-theme($light-theme);
@include mat.list-theme($light-theme);
//@include mat.menu-theme($light-theme);
@include mat.paginator-theme($light-theme);
//@include mat.progress-bar-theme($light-theme);
@include mat.progress-spinner-theme($light-theme);
//@include mat.radio-theme($light-theme);
@include mat.select-theme($light-theme);
@include mat.sidenav-theme($light-theme);
//@include mat.slide-toggle-theme($light-theme);
//@include mat.slider-theme($light-theme);
@include mat.sort-theme($light-theme);
//@include mat.stepper-theme($light-theme);
@include mat.table-theme($light-theme);
//@include mat.tabs-theme($light-theme);
@include mat.toolbar-theme($light-theme);
@include mat.tooltip-theme($light-theme);
//@include mat.tree-theme($light-theme);

// Theme CSS variables for custom components
$color-config: mat.get-color-config($light-theme);
$warn-palette: map.get($color-config, "warn");
$warn-500: map.get($warn-palette, 500);

html {
  --legalbot-warn-500: #{$warn-500};
}
