@import "styles/material";

// bootstrap
@import "../node_modules/bootstrap/scss/bootstrap";
/**
  FIXME: This is a temporary fix for the button component as the bootstrap
  button styles are overriding the button styles in this component.
  This workaround will be removed once the bootstrap styles are removed.
*/
button, [type=button], [type=reset], [type=submit] {
  appearance: none;
  -webkit-appearance: none;
}

// animaciones
@import "styles/animaciones";

// estilos globales
@import "styles/globales";

// componentes
@import "app/pages/customers/customers.component.scss";
@import "app/pages/studies/studies.component.scss";
@import "app/pages/error/error.component.scss";

@import "app/resultado/resultado.variables.scss";
@import "app/resultado/resultado.component.scss";
@import "app/resultado/resultado-nav/resultado-nav.component.scss";
@import "app/resultado/resultado-principal/resultado-principal.component.scss";

html,
body {
  height: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* TODO remove when bootstrap is removed 
 *  https://equipolegalbot.atlassian.net/browse/LB-1827
 */
label {
  margin-bottom: 0;
}
