.navegacion-principal {
  .nav-link {
    background: none;
    cursor: pointer;
    font-size: 14px;

    &.disabled {
      cursor: default;
    }

    &.active {
      text-decoration: underline;
    }

    &:focus {
      outline: none;
    }

    i.alerta {
      color: $danger;
    }

    i.aprobado {
      color: $success;
    }
  }

  .new-form {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    padding: 4px 0px;

    .nav-link {
      align-items: center;
      background: #e62e6a;
      border-radius: 4px;
      color: white;
      display: flex;
      font-size: 14px;
      gap: 8px;
      padding: 0px 8px;

      i {
        transform: rotate(45deg);
      }
    }
  }
}

.contenido-principal {
  width: 100%;
  padding: 10px;
  padding-right: 72px;
  border-left: solid 1px #dee2e6;
  background-image: none;
  background: white;

  .contenido {
    font-size: 14px;

    .alerta {
      margin-bottom: 15px;
      p {
        font-size: 12px;
        color: $danger;
        width: 100%;
        clear: both;
        margin: 0px;
      }
    }

    .resumen {
      font-size: 12px;
      background: #dee2e6;
      padding: 15px;
      border-radius: 3px;
      width: 100%;
      margin-bottom: 5px;

      table {
        th {
          font-weight: bold;
          padding-right: 15px;
        }

        button {
          margin-left: 5px;
          height: 31px;
          width: 31px;
          border-radius: 3px;
          padding: 0;
          border: 1px solid $green;
          background: #dee2e6;
          color: $green;
          display: inline-grid;

          i {
            font-size: 25px;
            margin-top: 2px;
          }

          &:hover {
            background: $green;
            color: white;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }

    .separador {
      width: 100%;
      clear: both;
      border-bottom: #dee2e6 solid 1px;
      margin: 15px 0px;
    }

    .botones {
      position: relative;
      top: -2px;
      float: right;

      button {
        margin-left: 5px;
        height: 31px;
        width: 31px;
        border-radius: 3px;
        padding: 0;
        border: 1px solid $green;
        background: white;
        color: $green;
        display: inline-grid;

        i {
          font-size: 25px;
          margin-top: 2px;
        }

        &:hover {
          background: $green;
          color: white;
        }

        &:focus {
          outline: none;
        }
      }
    }

    .contenido-item {
      width: 100%;
      overflow: auto;
      margin-bottom: 5px;
      padding: 5px;
      border: solid 1px #dee2e6;
      border-radius: 3px;

      .titulo {
        font-weight: bold;
        font-size: 14px;
        height: 25px;
        width: 100%;
      }

      .extraccion {
        font-weight: 100;
        font-size: 14px;

        .duracion-tags {
          width: 100%;
          margin-bottom: 5px;

          .tag {
            background: #ced4da;
            padding: 4px;
            border-radius: 2px;
            margin-right: 4px;
            font-size: 10px;
            color: black;
          }
        }
      }

      .alerta {
        font-size: 12px;
        color: $danger;
        width: 100%;
        clear: both;
      }

      &.en-linea .titulo,
      &.en-linea .extraccion {
        float: left;
      }

      &.en-linea .titulo {
        margin-right: 15px;
      }
    }

    .adjunto-item {
      margin-bottom: 5px;
    }

    td.upper {
      text-transform: uppercase;
    }

    td.rut {
      min-width: 140px;
    }

    td.derecho {
      text-align: right;
    }

    .tabla-accionistas {
      font-size: 14px;
    }

    .listado-socios {
      font-size: 16px;
    }

    ul.lista-socios {
      list-style: none;
      padding: 0;
    }

    .texto-inicio-facultados {
      font-weight: 100;
      font-size: 14px;
      width: 100%;
      overflow: auto;
      margin-bottom: 5px;
      padding: 5px;
      border: solid 1px #dee2e6;
      border-radius: 3px;
    }

    .tabla-facultades-planas {
      tr.no-seleccionada {
        background: #f8d7da;

        td {
          border-color: white;
        }
      }

      td {
        font-size: 12px;

        &.titulo-facultad {
          min-width: 300px;
        }

        &.texto-facultad {
          button {
            font-size: 10px;
            background: none;
            border: none;
            padding: 0;
            margin: 0;
            margin-right: 3px;
            cursor: pointer;

            &:hover {
              color: $color_boton_seleccionado;
            }

            &.no-link {
              cursor: default;
            }
          }
        }
      }
    }
  }
}
