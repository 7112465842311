.app-resultado-loading {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100vh;

  .contenedor-cargando {
    margin: auto;

    i {
      font-size: 40px;
    }
    p {
      margin: 5px 0px;
      padding: 0;
    }
  }
}

.app-resultado {
  height: 100vh;
  padding: 5px;

  .documents-container {
    width: 560px;
    height: 100%;
  }

  .study-container {
    background-color: white;
    background-image: url("/assets/images/brilliant.png");
    height: 100%;
  }
}
