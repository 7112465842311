.contenedor-resultado-nav {
  height: $alto_header;
  width: 100%;
  display: flex;
  flex-direction: row;

  .contenedor-listado-actuaciones {
    width: calc(100% - #{$botones_header});
    overflow: auto;
    display: flex;
    justify-content: space-between;

    .listado-actuaciones {
      ul {
        width: 100%;
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        li {
          display: contents;

          button {
            background: none;
            height: calc(#{$alto_header} - 10px);
            border-radius: 3px;
            border: 1px solid #dee2e6;
            padding: 6px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 5px;
            cursor: pointer;

            &.seleccionada {
              border: 1px solid $color_boton_seleccionado;
              background: $color_boton_seleccionado;
              color: white;
            }

            &:hover {
              border: 1px solid $color_boton_seleccionado;
            }

            p {
              padding: 0;
              margin: 0;
              font-size: 15px;
            }

            span {
              font-size: 13px;
            }
          }
        }
      }

      .listado-actuaciones-continuar {
        height: #{$alto_header};
        width: 30px;
        position: fixed;
        top: 0px;
        background: rgba(255, 255, 255, 0.9);
        right: calc(#{$ancho_texto} + #{$botones_header} + 5px);
      }
    }
  }

  .contenedor-botones-header {
    width: $botones_header;

    ul {
      width: 100%;
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;

      li {
        width: 50%;
        display: contents;

        button {
          background: none;
          border: none;
          height: calc(#{$alto_header} - 10px);
          width: inherit;
          border-left: 1px solid #dee2e6;
          padding: 8px;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;

          &:hover {
            color: $color_boton_seleccionado;
          }

          i {
            font-size: 25px;
          }

          span {
            margin-top: 5px;
            font-size: 8px;
          }
        }
      }
    }
  }
}

.contenedor-info-estudio {
  width: 100%;
  border-bottom: 1px solid #f7f7f7;
  border-top: 1px solid #f7f7f7;
  padding: 5px;
  margin-bottom: 5px;
  font-size: 10px;
  background: #f7f7f7;
}
