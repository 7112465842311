.error-container {
  height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;

  .message {
    background: #ffffff;
    margin: auto;
    width: 80%;
    max-width: 600px;
    box-shadow:
      0 1px 2px rgba(0, 0, 0, 0.12),
      0 1px 1px rgba(0, 0, 0, 0.24);
    padding: 50px;

    p {
      font-size: 14px;
      text-align: left;
      color: #555;
      font-weight: 100;
    }
  }
}
